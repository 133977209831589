import React from "react";

import Layout from "../components/layout";
import UnoSection from "../components/uno-section";

import ReserveFormMobile from "../components/reserve-form-mobile";

const ReservePage = () => (
  <Layout currentPage="reserve-mobile">
    <UnoSection minHeight="100vh" maxWidth="800px"  style={{topPadding: "10px"}}>
      <ReserveFormMobile />
    </UnoSection>
  </Layout>
);

export default ReservePage;
