import React from "react";
import styled from "styled-components";

import WhitePicker from "../images/reserve/color_picker/white.png";
import WhiteSelectedPicker from "../images/reserve/color_picker/white-selected.png";
import BlackPicker from "../images/reserve/color_picker/black.png";
import BlackSelectedPicker from "../images/reserve/color_picker/black-selected.png";
import BluePicker from "../images/reserve/color_picker/blue.png";
import BlueSelectedPicker from "../images/reserve/color_picker/blue-selected.png";
import PinkPicker from "../images/reserve/color_picker/pink.png";
import PinkSelectedPicker from "../images/reserve/color_picker/pink-selected.png";
import GreenPicker from "../images/reserve/color_picker/green.png";
import GreenSelectedPicker from "../images/reserve/color_picker/green-selected.png";

import SmallBlackImage from "../images/reserve/black_small_d.jpg";
import MediumBlackImage from "../images/reserve/black_medium_d.jpg";
import LargeBlackImage from "../images/reserve/black_large_d.jpg";

import SmallWhiteImage from "../images/reserve/white_small_d.jpg";
import MediumWhiteImage from "../images/reserve/white_medium_d.jpg";
import LargeWhiteImage from "../images/reserve/white_large_d.jpg";

import SmallBlueImage from "../images/reserve/blue_small_d.jpg";
import MediumBlueImage from "../images/reserve/blue_medium_d.jpg";
import LargeBlueImage from "../images/reserve/blue_large_d.jpg";

import SmallPinkImage from "../images/reserve/pink_small_d.jpg";
import MediumPinkImage from "../images/reserve/pink_medium_d.jpg";
import LargePinkImage from "../images/reserve/pink_large_d.jpg";

import SmallGreenImage from "../images/reserve/green_small_d.jpg";
import MediumGreenImage from "../images/reserve/green_medium_d.jpg";
import LargeGreenImage from "../images/reserve/green_large_d.jpg";

const StyledReserveForm = styled.form`
min-height: calc( 100vh - 200px );
display: block;
`;


const FormNavbar = styled.ul`
  list-style: none;
  display: flex;
  font-size: 14px;
  position: fixed;
  top: 78px;
  right: 0;
  left: 0;
  border-top: 5px solid white;
  border-bottom: 5px solid #f5f4f2;
`;

const UnoFormButton = styled.button`
  text-transform: uppercase;
  background: ${props => props.background};
  color: ${props => props.color};
  padding: 10px 10px;
  width: calc(100vw - 60px);
  border-radius: 50px;
  letter-spacing: 2px;
  display: block;
  margin: auto;
  border: 0px;
`;

const UnoFormSubmit = styled.button`
  text-transform: uppercase;
  background: #003dcf;
  color: #fff;
  padding: 10px 10px;
  border-radius: 50px;
  letter-spacing: 5px;
  border: none;
  width: calc(100vw - 60px);
`;

const FormNavbarItem = styled.li`
  flex: 1 0 ;
  background: #dedede;
  color: ##003dcf;
  padding: 4px 5px;
  text-transform: uppercase;
  letter-spacing: .2em;



  ${props =>
    props.active &&
    `
        color: #003dcf;
        background: #f5f4f2;
    `};
`;
const StageTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 20px auto;
  padding-top: 10px;
`;

const CupImage = styled.img`
  width: 100%;
  user-select: none;
  min-height: calc( 80vh - 280px );
  object-fit: cover;
  padding-left: 14px;
  padding-right: 14px; 
`;

const UnoColorPicker = styled.ul`
  margin: 20px auto;
  display: flex;
  width: 100%;
  height: 60px;
  padding-left: 14px;
  padding-right: 14px;
`;

const UnoColorOption = styled.li`
  flex: 1 1 auto;
  justify-content: space-between;
  margin-right: 10px;
  cursor: pointer;
  height: 20px; 

  &:last-of-type {
    margin-right: auto;
  }
`;

const ColorPickerOption = styled.img`
  width: 100%;
  scale: 10px;
`;

const UnoSizePicker = styled.ul`
  margin: 20px 14px;
  display: flex;
  min-height: 60px;
`;
const UnoSizeOption = styled.li`
  flex: 1 0 0;
  background: #e1e1e1;
  padding: 8px 20px;
  line-height: 1.4;
  text-align: center;
  justify-content: space-between;
  margin-right: 4px;
  cursor: pointer;

  &:last-of-type {
    margin-right: auto;
  }

  ${props =>
    props.active &&
    `
    background: #003dcf;
    color: white;
  `};
`;

const DesktopWrapper = styled.div`
  display: flex;
`;
const LeftColumn = styled.div`
  flex: 1 0 35%;
  text-align: left;
`;

const RightColumn = styled.div`
  padding-left: 40px;
  flex: 1 0 65%;
`;

const StyledInput = styled.input`
  font-size: 16px;
  padding: 0 15px;
  height: 42px;
  width: 100%;
  border: none;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  user-select: none;
  line-height: 20px;
`;

const StyledCheckbox = styled.input`
  margin-right: 4px;
  transform: scale(1.2);
`;

const PickerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Data = {
  formStages: [
    { title: "" },
    { title: "Choose a color" },
    { title: "Choose a size" },
    { title: "Complete sign up" },
  ],

  cupColors: ["white", "black", "blue", "green", "pink"],
  cupSizes: [
    { title: "Small", capacity: "12 oz.", value: "small" },
    { title: "Medium", capacity: "16 oz.", value: "medium" },
    { title: "Large", capacity: "20 oz.", value: "large" },
  ],

  colorPicker: {
    white: {
      normal: WhitePicker,
      selected: WhiteSelectedPicker,
    },
    black: {
      normal: BlackPicker,
      selected: BlackSelectedPicker,
    },
    blue: {
      normal: BluePicker,
      selected: BlueSelectedPicker,
    },
    green: {
      normal: GreenPicker,
      selected: GreenSelectedPicker,
    },
    pink: {
      normal: PinkPicker,
      selected: PinkSelectedPicker,
    },
  },

  cupImages: {
    black: {
      color: MediumBlackImage,
      small: SmallBlackImage,
      medium: MediumBlackImage,
      large: LargeBlackImage,
    },
    white: {
      color: MediumWhiteImage,
      small: SmallWhiteImage,
      medium: MediumWhiteImage,
      large: LargeWhiteImage,
    },
    blue: {
      color: MediumBlueImage,
      small: SmallBlueImage,
      medium: MediumBlueImage,
      large: LargeBlueImage,
    },
    pink: {
      color: MediumPinkImage,
      small: SmallPinkImage,
      medium: MediumPinkImage,
      large: LargePinkImage,
    },
    green: {
      color: MediumGreenImage,
      small: SmallGreenImage,
      medium: MediumGreenImage,
      large: LargeGreenImage,
    },
  },
};

class ReserveFormMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 1,
      color: "white",
      size: "medium",
      email: "",
      zipCode: "",
      brand: "",
      reserve: false,
      getUpdates: false,
    };
  }

  chooseColor = color => {
    this.setState({ color });
  };

  chooseSize = size => {
    this.setState({ size });
  };

  chooseStage = stage => {
    this.setState({ stage });
  };

  renderColorPicker() {
    const { color, stage } = this.state;

    return (
      <PickerWrapper
        style={{
          alignItems: "center",
          display: stage === 1 ? "flex" : "none",
        }}
      >
        <div style={{ flex: "1 1 auto" }}>
          <UnoColorPicker>
            {Data.cupColors.map(colorOption => {
              const currentColorOption = Data.colorPicker[colorOption];
              return (
                <UnoColorOption
                  key={colorOption}
                  onClick={() => {
                    this.chooseColor(colorOption);
                  }}
                >
                  <ColorPickerOption
                    src={
                      currentColorOption[
                        colorOption === color ? "selected" : "normal"
                      ]
                    }
                  />
                </UnoColorOption>
              );
            })}
            <input type="hidden" name="color" value={color} />
          </UnoColorPicker>
        </div>
      </PickerWrapper>
    );
  }

  renderSizePicker() {
    const { size, stage } = this.state;

    return (
      <PickerWrapper
        style={{
          alignItems: "center",
          display: stage === 2 ? "flex" : "none",
        }}
        disabled={stage < 2}
      >
        <div style={{ flex: "1 1 auto" }}>
          <UnoSizePicker>
            {Data.cupSizes.map(({ title, capacity, value }) => {
              return (
                <UnoSizeOption
                  key={title}
                  active={value === size}
                  onClick={() => {
                    this.chooseSize(value);
                  }}
                >
                  <div style={{ fontFamily: "NewlineBold" }}>{title}</div>
                  <div style={{ fontSize: 12 }}>{capacity}</div>
                </UnoSizeOption>
              );
            })}
            <input type="hidden" name="size" value={size} />
          </UnoSizePicker>
        </div>
      </PickerWrapper>
    );
  }

  renderMobileCupImage() {
    const { stage, color, size } = this.state;
    let cupImage;
    if (stage <= 1) {
      cupImage = Data.cupImages[color].color;
    } else {
      cupImage = Data.cupImages[color][size];
    }

    return <CupImage src={cupImage} />;
  }

  renderDesktopCupImage() {
    const { color, size } = this.state;
    const cupImage = Data.cupImages[color][size];
    return <CupImage src={cupImage} />;
  }

  renderMobileFormNav() {
    const { stage } = this.state;
    return (
      <FormNavbar>
        <FormNavbarItem
          active={stage === 1}
        >
          Color
        </FormNavbarItem>
        <FormNavbarItem
          active={stage === 2}
        >
          Size
        </FormNavbarItem>
        <FormNavbarItem
          active={stage === 3}
        >
          Done
        </FormNavbarItem>
      </FormNavbar>
    );
  }

  renderFormDetails() {
    const { reserve, getUpdates, email, zipCode, brand, stage } = this.state;

    return (
      <PickerWrapper style={{ display: stage === 3 ? "block" : "none" }}>
        <div style={{ marginBottom: 4 }}>
          <StyledInput
            type="email"
            name="email"
            id="email"
            style={{ width: "90%" }}
            required
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
            placeholder="Email Address"
          />
        </div>
        <div>
          <br />
          <StyledInput
            type="text"
            name="zipcode"
            id="zipcode"
            pattern="\d*"
            maxLength="5"
            placeholder="Zip Code"
            required
            value={zipCode}
            style={{ width: "90%" }}
            onChange={e => this.setState({ zipCode: e.target.value })}
          />
        </div>
       {/*
        <div style={{ fontSize: 12, color: "#ababab", marginBottom: -20 }}>
          Why this helps us{" "}
          <span
            style={{
              background: "#dedede",
              color: "#bcbcbc",
              width: 14,
              height: 14,
              lineHeight: "14px",
              borderRadius: "50%",
              textAlign: "center",
              verticalAlign: "middle",
              display: "inline-block",
              paddingLeft: 1,
              cursor: "help",
            }}
            data-toggle="tooltip"
            data-placement="right"
            title="Tooltip on right"
          >
            ?
          </span>
        </div>
        */}
        <div style={{ marginBottom: 30 }}>
          <br />
          <StyledInput
            type="text"
            name="brand"
            id="brand"
            placeholder="Your favorite beverage brand"
            value={brand}
            onChange={e => this.setState({ brand: e.target.value })}
            style={{ width: "90%" }}
          />
        </div>

        <div style={{ lineHeight: 1, margin: "20px auto", textAlign: "left", width: "90%"}}>
          <StyledCheckbox
            id="reserve_cup"
            name="reserve_cup"
            type="checkbox"
            checked={reserve}
            required
            value={reserve}
            onChange={() => {
              this.setState(({ reserve }) => ({
                reserve: !reserve,
              }));
            }}
          />
          <StyledLabel htmlFor="reserve_cup">
            Reserve my Uno Cup for free. <a href="/reserve-terms/" target="blank">See Terms</a>.
          </StyledLabel>
          <br />
          <StyledCheckbox
            id="subscribe_updates"
            name="subscribe_updates"
            type="checkbox"
            checked={getUpdates}
            value={getUpdates}
            onChange={() => {
              this.setState(({ getUpdates }) => ({
                getUpdates: !getUpdates,
              }));
            }}
          />

          <StyledLabel htmlFor="subscribe_updates">
            Send me periodical updates about Uno.
          </StyledLabel>

          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="reserve-mobile" />
        </div>
        <UnoFormSubmit type="submit">Sign Up</UnoFormSubmit>
      </PickerWrapper>
    );
  }
  render() {
    const { stage } = this.state;
    const stageTitle = Data.formStages[stage].title;

    return (
      <StyledReserveForm
        name="reserve-mobile"
        method="POST"
        action="/thank-you"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        {this.renderMobileFormNav()}

        <StageTitle>{stageTitle}</StageTitle>

        {stage < 3 && this.renderMobileCupImage()}
        {this.renderColorPicker()}
        {this.renderSizePicker()}
        {this.renderFormDetails()}
        {stage < 3 && (
          <UnoFormButton
            background="black"
            color="white"
            onClick={e => {
              e.preventDefault();
              this.setState(({ stage }) => {
                return { stage: stage + 1 };
              });
            }}
          >
            Next
          </UnoFormButton>
        )}
      </StyledReserveForm>
    );
  }
}

export default ReserveFormMobile;
